<template>
  <el-form ref="ruleForm" class="ruleForm" :rules="ruleFormRules" :model="ruleForm" label-width="120px" size="small"
    style="width: 500px;">
    <el-form-item label="考试计划编号" prop="planNumber">
      <el-input type="text" v-model="ruleForm.planNumber" clearable placeholder="请输入考试计划编号" />
    </el-form-item>
    <el-form-item label="考试计划名称" prop="planName">
      <el-input type="text" v-model="ruleForm.planName" clearable placeholder="请输入考试计划名称" />
    </el-form-item>
    <el-form-item label="考试日期" prop="examinationDate">
      <el-date-picker v-model="ruleForm.examinationDate" size="small" type="date" value="yyyy-MM-dd"
        value-format="yyyy-MM-dd" placeholder="请选择考试日期" />
    </el-form-item>
    <el-form-item label="考点" prop="centerId">
      <el-select clearable size="small" v-model="ruleForm.centerId" placeholder="请选择">
        <el-option v-for="item in centerIdList" :key="item.centerId" :label="item.centerName"
          :value="item.centerId"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="作业类别" prop="bankId">
      <el-select clearable size="small" v-model="ruleForm.bankId" placeholder="请选择">
        <el-option v-for="item in bankList" :key="item.bankId" :label="item.workCategoryName"
          :value="item.bankId"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item v-if="operateType != 'examine'">
      <el-button class="bgc-bv" @click="$router.back()">取 消</el-button>
      <el-button class="bgc-bv" @click="sureData">确 定</el-button>
    </el-form-item>
  </el-form>
</template>
<script>
export default {
  name: "examPlan_info_1",
  components: {},
  props: {
    // 考试计划id
    examinationId: {
      type: String,
      default: "",
    },
    // 审核人员操作
    operateType: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      // 基本信息
      ruleForm: {
        planNumber: "", // 考试计划编号
        planName: "", // 考试计划名称
        examinationDate: "", // 考试日期
        centerId: "", // 考点
        bankId: "", // 考试题库
      },
      // 申请&新增弹框数据校验
      ruleFormRules: {
        planNumber: [
          { required: true, message: "请输入考试计划编号", trigger: "blur" },
        ],
        planName: [
          { required: true, message: "请输入考试计划名称", trigger: "blur" },
        ],
        examinationDate: [
          { required: true, message: "请选择选择考试日期",trigger: "change" }
        ],
        centerId: [
          { required: true, message: "请选择考点",trigger: "change" }
        ],
        bankId: [
          { required: true, message: "请选择考试题库",trigger: "change" }
        ],
      },
      workCategoryList: [], // 操作项目 - 下拉数据
      centerIdList: [], // 所属考点 - 下拉数据
      bankList: [], // 考试题库 - 下拉数据
    };
  },
  computed: {},
  mounted() {
  },
  created() {
    this.getDictionary();
    this.getCenterIdList();
    this.getBankList();
    if (this.examinationId) {
      this.getDetail();
    }
  },
  methods: {
    // 获取 - 字典
    getDictionary() {
      // 操作项目
      let workCategory = this.$setDictionary("PA_WORK_CATEGORY", "list");
      for (const key in workCategory) {
        this.workCategoryList.push({
          value: key,
          label: workCategory[key],
        });
      }
    },
    // 获取 - 所属考点 - 下拉数据
    getCenterIdList() {
      this.$post("/biz/center/pa-test-center/centerList", {}, 3000, true, 10).then((res) => {
        this.centerIdList = res.data || [];
        // 如过只有一个考点默认选中第一个
        if (res.data.length == 1) {
          this.ruleForm.centerId = res.data[0].centerId;
        }
      });
    },
    // 获取 - 考试题库 - 下拉数据
    getBankList(bankName) {
      this.$post("/biz/bank/pa-question-bank/work/category/enable", { bankName }, 3000, true, 10).then((res) => {
        this.bankList = res.data || [];
      });
    },
    // 获取 - 详情
    getDetail() {
      this.$post("/biz/plan/pa-plan-examination/getPlan", {
        examinationId: this.examinationId
      }, 3000, true, 10).then((res) => {
        this.ruleForm = { ...this.ruleForm, ...res.data };
        this.ruleForm.examinationDate = res.data.examinationDate.replaceAll('/','-');
      });
    },
    // 保存
    sureData() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          let url = '';
          if (this.examinationId) {
            url = '/biz/plan/pa-plan-examination/modifyPlan'
          } else {
            url = '/biz/plan/pa-plan-examination/addPlan'
          }
          this.$post(url, this.ruleForm, 3000, true, 10)
            .then((res) => {
              this.$message.success('操作成功');
              this.$router.back();
            })
            .catch((err) => {
              return;
            });
        }
      });
    },
  },
};
</script>
<style lang="less" scoped></style>
