<template>
  <div>
    <div class="operationControl personalSpecialOrder">
      <div class="searchbox">
        <div title="姓名" class="searchboxItem ci-full">
          <span class="itemLabel">姓名:</span>
          <el-input v-model="retrievalData.fullname" type="text" size="small" placeholder="姓名" clearable />
        </div>
        <div title="身份证号" class="searchboxItem ci-full">
          <span class="itemLabel">身份证号:</span>
          <el-input v-model="retrievalData.idCard" type="text" size="small" placeholder="身份证号" clearable />
        </div>
        <el-button class="bgc-bv" round @click="getData()">查询</el-button>
        <el-button class="bgc-bv" round @click="openAddEditSupervise">新增</el-button>
      </div>
    </div>
    <div class="framePage-scroll">
      <div class="ovy-a">
        <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
          style="width: 100%" stripe :header-cell-style="tableHeader">
          <el-table-column label="序号" align="center" type="index" :index="indexMethod" width="80px" />
          <el-table-column label="姓名" align="left" show-overflow-tooltip prop="officerName" min-width="120" />
          <el-table-column label="身份证号" align="left" show-overflow-tooltip prop="idCard" min-width="200" />
          <el-table-column label="手机号" align="left" show-overflow-tooltip prop="mobile" min-width="150" />
          <el-table-column label="创建时间" align="left" show-overflow-tooltip prop="createTime" width="160px" />
          <el-table-column label="操作" align="center" fixed="right" width="120px">
            <template slot-scope="scope">
              <el-button type="text" style="padding: 0px 5px" size="mini" @click="to_delete(scope.row)">删除</el-button>
            </template>
          </el-table-column>
          <Empty slot="empty" />
        </el-table>
      </div>
    </div>
    <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
    <!-- 弹框 - 新增&编辑 - 考官 -->
    <el-dialog title="新增考官" :visible.sync="addEditSuperviseData.dialogVisible" width="500px"
      @close="closeAddEditSupervise">
      <el-form ref="addEditSuperviseData" label-width="100px" size="small" :rules="addEditSuperviseDataRules"
        :model="addEditSuperviseData">
        <el-form-item label="考官" prop="officerId">
          <el-select size="small" v-model="addEditSuperviseData.officerId" remote :remote-method="getSuperviseList"
            filterable clearable placeholder="请筛选">
            <el-option v-for="item in superviseList" :key="item.officerId" :label="item.officerName + '-' + item.idCard"
              :value="item.officerId"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="closeAddEditSupervise" class="bgc-bv">取 消</el-button>
        <el-button size="small" @click="sureAddEditSupervise" class="bgc-bv">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
  name: "examPlan_info_5",
  components: {
    Empty,
    PageNum,
  },
  props: {
    // 考试计划id
    examinationId: {
      type: String,
      default: "",
    },
  },
  mixins: [List],
  data() {
    return {
      // 检索数据
      retrievalData: {
        fullname: "", // 姓名
        idCard: "", // 身份证号
      },
      // 添加&新增 - 弹框数据
      addEditSuperviseData: {
        dialogVisible: false, // 弹框状态
        officerId: "", // 考官id
      },
      // 添加&新增 - 弹框数据校验
      addEditSuperviseDataRules: {
        officerId: [
          { required: true, trigger: "blur", message: "请选择考官" }
        ],
      },
      // 考官 - 下拉数据
      superviseList: [],
    };
  },
  watch: {},
  computed: {

  },
  mounted() { },
  created() {
    
  },
  methods: {
    // 查询 - 列表
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        ...
        {
          examinationId: this.examinationId,
          ... this.retrievalData
        }
      };
      this.doFetch(
        {
          url: "/biz/plan/pa-plan-examination-officer/pageList",
          params,
          pageNum,
        }, true, 10
      );
    },
    // 获取 - 下拉数据
    getSuperviseList(query) {
      this.$post("/biz/officer/pageList", {
        officerName: query,
        pageNum: 1,
        pageSize: 10,
      }, 3000, true, 10)
        .then((res) => {
          this.superviseList = res.data.list || [];
        })
        .catch(() => {
          return;
        });
    },
    // 新增编辑 - 弹框 - 打开
    openAddEditSupervise() {
      this.addEditSuperviseData.dialogVisible = true;
      this.getSuperviseList()
    },
    // 新增编辑 - 弹框 - 关闭
    closeAddEditSupervise() {
      this.addEditSuperviseData = this.$options.data().addEditSuperviseData;
    },
    // 新增编辑 - 弹框 - 确定
    sureAddEditSupervise() {
      this.$refs['addEditSuperviseData'].validate((valid) => {
        if (valid) {
          this.$post("/biz/plan/pa-plan-examination-officer/insert", {
            examinationId: this.examinationId,
            officerId: this.addEditSuperviseData.officerId,
          }, 3000, true, 10)
            .then((res) => {
              this.$message.success('操作成功');
              this.closeAddEditSupervise();
              this.getData();
            })
            .catch((err) => {
              console.log(err)
              return;
            });
        }
      })
    },
    // 删除
    to_delete(row) {
      this.$post("/biz/plan/pa-plan-examination-officer/delete", {
        examinationOfficerId: row.examinationOfficerId
      }, 3000, true, 10).then((res) => {
        this.$message.success('操作成功');
        this.getData(this.pageNum);
      });
    },
  },
};
</script>
<style lang="less" scoped></style>