<template>
  <div>
    <div class="operationControl personalSpecialOrder">
      <div class="searchbox">
        <div title="姓名" class="searchboxItem ci-full">
          <span class="itemLabel">姓名:</span>
          <el-input v-model="retrievalData.fullName" type="text" size="small" placeholder="姓名" clearable />
        </div>
        <div title="身份证号" class="searchboxItem ci-full">
          <span class="itemLabel">身份证号:</span>
          <el-input v-model="retrievalData.idCard" type="text" size="small" placeholder="身份证号" clearable />
        </div>
        <el-button class="bgc-bv" round @click="getData()">查询</el-button>
        <el-button class="bgc-bv" round @click="exportSeeExaminationRoom_1()">导出登记表</el-button>
        <el-button class="bgc-bv" round @click="exportSeeExaminationRoom_2()">导出汇总表</el-button>
      </div>
    </div>
    <div class="framePage-scroll">
      <div class="ovy-a">
        <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
          style="width: 100%" stripe :header-cell-style="tableHeader">
          <el-table-column label="序号" align="center" type="index" :index="indexMethod" width="80px" />
          <el-table-column label="姓名" align="left" show-overflow-tooltip prop="fullName" min-width="120" />
          <el-table-column label="身份证号" align="left" show-overflow-tooltip prop="idCard" min-width="200" />
          <el-table-column label="手机号" align="left" show-overflow-tooltip prop="mobile" min-width="150" />
          <el-table-column label="准考证号" align="left" show-overflow-tooltip prop="examinationNo" min-width="150" />
          <el-table-column label="科目" align="center" show-overflow-tooltip>
            <el-table-column label="科目一" align="left" show-overflow-tooltip prop="lastSubjectOneScore" min-width="80" />
            <el-table-column label="科目二" align="left" show-overflow-tooltip prop="lastSubjectTwoScore" min-width="80" />
            <el-table-column label="科目三" align="left" show-overflow-tooltip prop="lastSubjectThreeScore"
              min-width="80" />
            <el-table-column label="科目四" align="left" show-overflow-tooltip prop="lastSubjectFourScore"
              min-width="80" />
          </el-table-column>
          <el-table-column label="补考科目" align="center" show-overflow-tooltip>
            <el-table-column label="科目一" align="left" show-overflow-tooltip prop="subjectOneScore" min-width="80" />
            <el-table-column label="科目二" align="left" show-overflow-tooltip prop="subjectTwoScore" min-width="80" />
            <el-table-column label="科目三" align="left" show-overflow-tooltip prop="subjectThreeScore" min-width="80" />
            <el-table-column label="科目四" align="left" show-overflow-tooltip prop="subjectFourScore" min-width="80" />
          </el-table-column>
          <el-table-column label="操作" align="center" fixed="right" width="120px">
            <template slot-scope="scope">
              <el-button type="text" style="padding: 0px 5px" size="mini"
                @click="openExaminer(scope.row)">查看成绩</el-button>
              <el-button type="text" style="padding: 0px 5px" size="mini"
                @click="exportSeeExaminationRoomOne(scope.row)">下载</el-button>
            </template>
          </el-table-column>
          <Empty slot="empty" />
        </el-table>
      </div>
    </div>
    <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
    <!-- 弹框 - 查看考试成绩 -->
    <el-dialog title="成绩" :visible.sync="addEditExaminerData.dialogVisible" width="500px" @close="closeAddEditStuden">
      <table border="1" class="cj">
        <tr>
          <td>姓名</td>
          <td colspan="4">{{ addEditExaminerData.fullName }}</td>
        </tr>
        <tr>
          <td>身份证号</td>
          <td colspan="4">{{ addEditExaminerData.idCard }}</td>
        </tr>
        <tr>
          <td>手机号</td>
          <td colspan="4">{{ addEditExaminerData.mobile }}</td>
        </tr>
        <tr>
          <td>准考证号</td>
          <td colspan="4">{{ addEditExaminerData.examinationNo }}</td>
        </tr>
        <tr>
          <td>考核项目</td>
          <td>考试科目</td>
          <td>成绩</td>
          <td>考试科目</td>
          <td>成绩</td>
        </tr>
        <tr>
          <td rowspan="2">{{ addEditExaminerData.planName }}</td>
          <td>科目一</td>
          <td>{{ addEditExaminerData.subjectOneScore }}</td>
          <td>科目三</td>
          <td>{{ addEditExaminerData.subjectThreeScore }}</td>
        </tr>
        <tr>
          <td>科目二</td>
          <td>{{ addEditExaminerData.subjectTwoScore }}</td>
          <td>科目四</td>
          <td>{{ addEditExaminerData.subjectFourScore }}</td>
        </tr>
        <tr>
          <td>否决项及说明</td>
          <td colspan="4">{{ addEditExaminerData.vetoItem }}</td>
        </tr>
        <tr>
          <td>实操总成绩</td>
          <td colspan="4">{{ addEditExaminerData.totalScore }}</td>
        </tr>
      </table>
    </el-dialog>
  </div>
</template>

<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
export default {
  name: "examPlan_info_4",
  components: {
    Empty,
    PageNum,
  },
  props: {
    // 考试计划id
    examinationId: {
      type: String,
      default: "",
    },
  },
  mixins: [List],
  data() {
    return {
      // 检索数据
      retrievalData: {
        fullName: "", // 姓名
        idCard: "", // 身份证号
      },
      // 添加&新增 - 弹框数据
      addEditExaminerData: {
        dialogVisible: false, // 弹框状态

      },
    };
  },
  watch: {},
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  mounted() { },
  created() {

  },
  methods: {
    // 查询 - 列表
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        ...
        {
          examinationId: this.examinationId,
          ... this.retrievalData
        }
      };
      this.doFetch(
        {
          url: "/biz/plan/pa-plan-examination-performance/comp/pageList",
          params,
          pageNum,
        }, true, 10
      );
    },
    // 新增编辑 - 弹框 - 打开
    openExaminer(row) {
      if (row) {
        this.addEditExaminerData = { ...this.addEditExaminerData, ...row };
        if (!this.addEditExaminerData.performanceId) {
          this.addEditExaminerData.subjectOneScore = row.lastSubjectOneScore;
          this.addEditExaminerData.subjectTwoScore = row.lastSubjectTwoScore;
          this.addEditExaminerData.subjectThreeScore = row.lastSubjectThreeScore;
          this.addEditExaminerData.subjectFourScore = row.lastSubjectFourScore;
          this.addEditExaminerData.totalScore = row.lastTotalScore;
          this.addEditExaminerData.vetoItem = row.lastVetoItem;
        }
      }
      this.addEditExaminerData.dialogVisible = true;
    },
    // 新增编辑 - 弹框 - 关闭
    closeAddEditStuden() {
      this.addEditExaminerData = this.$options.data().addEditExaminerData;
    },
    // 导出登记表 - 单个导出
    exportSeeExaminationRoomOne(row) {
      this.$post("/biz/plan/pa-plan-examination-performance/comp/downloadScore", {
        examinationUserId: row.examinationUserId,
      }, 3000, true, 10).then((res) => {
        window.open(res.message);
      }).catch((err) => {
        console.log(err)
        return;
      });
    },
    // 导出登记表
    exportSeeExaminationRoom_1() {
      const params = {
        examinationId: this.examinationId,
        ... this.retrievalData
      };
      this.$post(
        "/biz/plan/pa-plan-examination-performance/comp/downloadScoreAll",
        params, 3000, true, 10
      )
        .then((res) => {
          const arr = res.data;
          for (let item of arr) {
            if (!this.downloadItems.includes(item.taskId)) {
              this.$store.dispatch("pushDownloadItems", item.taskId);
            } else {
              this.$message.warning(
                "[" + item.fileName + "]已经申请下载,请耐心等待"
              );
            }
          }
        })
        .catch(() => {
          return;
        });
    },
    // 导出汇总表
    exportSeeExaminationRoom_2() {
      const params = {
        examinationId: this.examinationId,
        ... this.retrievalData
      };
      this.$post(
        "/biz/plan/pa-plan-examination-performance/downloadScore/statisticalTable",
        params, 3000, true, 10
      )
        .then((res) => {
          window.open(res.message);
        })
        .catch(() => {
          return;
        });
    },
  },
};
</script>
<style lang="less" scoped>
.cj {
  border-collapse: separate;
  /* 确保边框分开，以便能看到间距效果 */
  border-spacing: 0;

  /* 设置表格单元格的间距 */
  td {
    padding: 0;
    /* 设置单元格内容与边框之间的内边距 */
    line-height: 30px;
    /* 设置单元格与其他单元格之间的外边距 */
    padding-left: 10px;
  }
}
</style>
