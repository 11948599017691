<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">考试管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">考试计划</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">详情</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <!-- 是审核页面进来的才有此操作 -->
            <div class="top_cz" v-if="operateType == 'examine'">
              <el-button size="small" class="bgc-bv" @click="c_pass">通 过</el-button>
              <el-button size="small" class="bgc-bv" @click="openPassNoPass">驳 回</el-button>
              <el-button size="small" class="bgc-bv" @click="$router.back()">返 回</el-button>
            </div>
            <el-tabs v-model="activeName" :before-leave="beforeTabLeave" @tab-click="tabChange">
              <el-tab-pane label="基本信息" name="type_1">
                <type_1 ref="type_1" :examinationId="examinationId" :operateType="operateType" />
              </el-tab-pane>
              <el-tab-pane label="考生列表" name="type_2" v-if="examinationId">
                <type_2 ref="type_2" :examinationId="examinationId" :operateType="operateType" />
              </el-tab-pane>
              <el-tab-pane label="考评员列表" name="type_3" v-if="examinationId">
                <type_3 ref="type_3" :examinationId="examinationId" :operateType="operateType" />
              </el-tab-pane>
              <el-tab-pane label="考官列表" name="type_4" v-if="examinationId">
                <type_5 ref="type_4" :examinationId="examinationId" />
              </el-tab-pane>
              <el-tab-pane label="考试成绩" name="type_5" v-if="examinationId && operateType != 'examine'">
                <type_4 ref="type_5" :examinationId="examinationId" />
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div>
    </div>
    <!-- 审核不通过 - 弹框 -->
    <el-dialog title="审核" :visible.sync="passNoPass.dialogVisible" width="500px" @close="closePassNoPass">
      <el-form ref="passNoPass" label-width="100px" size="small" :rules="passNoPassRules" :model="passNoPass">
        <el-form-item label="驳回理由:" prop="auditDesc">
          <el-input type="textarea" v-model="passNoPass.auditDesc" clearable placeholder="请输入驳回理由" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="closePassNoPass" class="bgc-bv">取 消</el-button>
        <el-button size="small" @click="c_passNoPass" class="bgc-bv">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import type_1 from "@/views/practicalAssessmentSystem/examinationAffairsManage/examPlan_info_1.vue"; //基本信息
import type_2 from "@/views/practicalAssessmentSystem/examinationAffairsManage/examPlan_info_2.vue"; //考生列表
import type_3 from "@/views/practicalAssessmentSystem/examinationAffairsManage/examPlan_info_3.vue"; //考评员列表
import type_4 from "@/views/practicalAssessmentSystem/examinationAffairsManage/examPlan_info_4.vue"; //考试成绩列表
import type_5 from "@/views/practicalAssessmentSystem/examinationAffairsManage/examPlan_info_5.vue"; //考官列表
export default {
  name: "examinationAffairsManage_examPlan_info",
  components: {
    type_1,
    type_2,
    type_3,
    type_4,
    type_5
  },
  data() {
    return {
      // tap选项卡的选中name
      activeName: "type_1",
      examinationId: "", // 考试计划id
      operateType: "", // 审核操作
      // 审核 - 弹框数据
      passNoPass: {
        dialogVisible: false, // 弹框状态
        auditState: "", // 审核状态：00 待提交 10 未审核 20 通过 30 驳回
        auditDesc: "", // 审核备注
      },
      // 审核 - 弹框数据校验
      passNoPassRules: {
        auditDesc: [
          { required: true, message: "请输入驳回理由", trigger: "blur" },
        ],
      },
    };
  },
  computed: {},
  mounted() {
  },
  created() {
    this.examinationId = this.$route.query?.examinationId ?? ""; // 考试计划id
    this.operateType = this.$route.query?.operateType ?? ""; // 审核操作
  },
  methods: {
    // 点击tabs选项卡
    tabChange(tab, event) {
      this.activeName = tab.name;
    },
    // 打开 - 审核不通过 - 弹框
    openPassNoPass() {
      this.passNoPass = { ...this.passNoPass, examinationId: this.examinationId, auditState: 30 };
      this.passNoPass.dialogVisible = true;
    },
    // 关闭 - 审核不通过 - 弹框
    closePassNoPass() {
      this.passNoPass = this.$options.data().passNoPass;
    },
    // 通过
    c_pass() {
      this.$post("/biz/plan/audit/audit", {
        examinationId: this.examinationId,
        auditState: '20'
      }, 3000, true, 10)
        .then((res) => {
          this.$message.success('操作成功')
          this.getData(this.pageNum);
        })
        .catch((err) => {
          return;
        });
    },
    // 不通过
    c_passNoPass() {
      this.$refs['passNoPass'].validate((valid) => {
        if (valid) {
          this.$post("/biz/plan/audit/audit", this.passNoPass, 3000, true, 10)
            .then((res) => {
              this.$message.success('操作成功')
              this.getData(this.pageNum);
              this.closePassNoPass();
            })
            .catch((err) => {
              return;
            });
        }
      })
    },
  },
};
</script>
<style lang="less" scoped>
.top_cz {
  text-align: right;
}
</style>
