<template>
  <div>
    <div class="operationControl personalSpecialOrder">
      <div class="searchbox">
        <div title="考生姓名" class="searchboxItem ci-full">
          <span class="itemLabel">考生姓名:</span>
          <el-input v-model="retrievalData.fullName" type="text" size="small" placeholder="考生姓名" clearable />
        </div>
        <div title="身份证号" class="searchboxItem ci-full">
          <span class="itemLabel">身份证号:</span>
          <el-input v-model="retrievalData.idCard" type="text" size="small" placeholder="身份证号" clearable />
        </div>
        <template v-if="operateType != 'examine'">
          <el-button class="bgc-bv" round @click="getData()">查询</el-button>
          <el-button class="bgc-bv" round @click="openAddEditStuden()">新增</el-button>
          <el-button class="bgc-bv" round @click="openStudentImportData()">导入</el-button>
        </template>
      </div>
    </div>
    <div class="framePage-scroll">
      <div class="ovy-a">
        <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
          style="width: 100%" stripe :header-cell-style="tableHeader">
          <el-table-column label="序号" align="center" type="index" :index="indexMethod" width="80px" />
          <el-table-column label="姓名" align="left" show-overflow-tooltip prop="fullName" min-width="120" />
          <el-table-column label="性别" align="left" show-overflow-tooltip prop="sex" min-width="120" >
            <template slot-scope="{ row }">
              {{ $setDictionary("SEX", row.sex) }}
            </template>
          </el-table-column>
          <el-table-column label="身份证号" align="left" show-overflow-tooltip prop="idCard" min-width="200" />
          <el-table-column label="准考证号" align="left" show-overflow-tooltip prop="examinationNo" min-width="120" />
          <el-table-column label="手机号" align="left" show-overflow-tooltip prop="mobile" min-width="150" />
          <el-table-column label="创建时间" align="left" show-overflow-tooltip prop="createTime" width="160px" />
          <el-table-column label="操作" align="center" fixed="right" width="100px" v-if="operateType != 'examine'">
            <template slot-scope="scope">
              <el-button type="text" style="padding: 0px 5px" size="mini"
                @click="openAddEditStuden(scope.row)">编辑</el-button>
              <el-button type="text" style="padding: 0px 5px" size="mini" @click="delete_data(scope.row)">删除</el-button>
            </template>
          </el-table-column>
          <Empty slot="empty" />
        </el-table>
      </div>
    </div>
    <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
    <!-- 弹框 - 新增&编辑 - 考生 -->
    <el-dialog :title="addEditStudentData.aaaid ? '编辑考生' : '新增考生'" :visible.sync="addEditStudentData.dialogVisible"
      width="500px" @close="closeAddEditStuden" :close-on-click-modal="false" :close-on-press-escape="false">
      <el-form ref="addEditStudentData" label-width="100px" size="small" :rules="addEditStudentDataRules"
        :model="addEditStudentData">
        <el-form-item label="学生姓名:" prop="fullName">
          <el-input type="text" v-model="addEditStudentData.fullName" clearable placeholder="请输入学生姓名" />
        </el-form-item>
        <el-form-item label="准考证号:" prop="examinationNo">
          <el-input type="text" v-model="addEditStudentData.examinationNo" clearable placeholder="请输入准考证号" />
        </el-form-item>
        <el-form-item label="身份证号:" prop="idCard">
          <el-input type="text" v-model="addEditStudentData.idCard" clearable placeholder="请输入身份证号" />
        </el-form-item>
        <el-form-item label="联系方式:" prop="mobile">
          <el-input type="text" v-model="addEditStudentData.mobile" clearable placeholder="请输入联系方式" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="closeAddEditStuden" class="bgc-bv">取 消</el-button>
        <el-button size="small" @click="sureAddEditStuden" class="bgc-bv">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 弹框 -  学员导入 -->
    <el-dialog title="学员导入" :visible.sync="studentImportData.dialogVisible" width="50%" center
      :before-close="closeStudentImportData" class="exportdialog">
      <div class="flexdcc">
        <div class="export-box">
          <h1>
            1
            <span>下载并填写导入模板</span>
          </h1>
          <div class="df">
            <span @click="downloadExcelgs" style="margin-top: 20px;color: #409EFF;cursor: pointer">
              学员导入模板.xls
            </span>
          </div>
        </div>
        <div class="export-box">
          <h1>
            2
            <span>导入编写好的Excel文档</span>
          </h1>
          <div class="flexdc">
            <div class="df" style="margin: 20px 0 10px">
              <el-upload class="upload-demo upload-workers" :action="actionUrl" :on-error="handleError"
                :on-success="handleSuccess" :on-change="uploadChange" :show-file-list="false" :auto-upload="false">
                <el-button class="bgc-bv" style="font-size: 12px;">浏览</el-button>
              </el-upload>
              <p v-if="studentImportData.fileName" style="margin-top: 10px; margin-left: 10px">
                当前选择文件：
                <span style="color: #f46173">{{ studentImportData.fileName }}</span>
              </p>
              <p v-else style="margin-top: 10px; margin-left: 10px">
                未选择文件
              </p>
            </div>
            <div>
              <el-button class="bgc-bv" size="mini" style="margin-top: 10px; height: 35px"
                :disabled="studentImportData.doExportInDisabled" @click="doExport">开始导入</el-button>
            </div>
            <div style="margin-top: 10px" v-if="studentImportData.progressVal > 0">
              <el-progress :text-inside="true" :stroke-width="20"
                :percentage="studentImportData.progressVal"></el-progress>
            </div>
          </div>
        </div>
        <div class="export-box">
          <h1>
            3
            <span>导入结果</span>
          </h1>
          <div class="df studentNum">
            <span>学员总数量:{{ studentImportData.totalNum }}人;</span>
            <span>成功:{{ studentImportData.correctNum }}人;</span>
            <span>失败:{{ studentImportData.errorNum }}人;</span>
          </div>
          <template v-if="studentImportData.errorNum != 0">
            <div>
              <el-button class="bgc-bv" style="margin-top: 15px; height: 35px" size="mini"
                @click="doExportError">导出错误数据</el-button>
            </div>
            <div style="margin-top: 15px; color: #dd1d35">
              有导入失败学员，请导出错误数据，将导入失败学员调整后重新上传
            </div>
          </template>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
  name: "examPlan_info_2",
  components: {
    Empty,
    PageNum,
  },
  props: {
    // 考试计划id
    examinationId: {
      type: String,
      default: "",
    },
    // 审核人员操作
    operateType: {
      type: String,
      default: "",
    },
  },
  mixins: [List],
  data() {
    return {
      // 检索数据
      retrievalData: {
        fullName: "", // 姓名
        idCard: "", // 身份证号
      },
      // 添加&新增 - 弹框数据
      addEditStudentData: {
        dialogVisible: false, // 弹框状态
        examinationUserId: "", // 考生id
        fullName: "", // 学生姓名
        examinationNo: "", // 准考证号
        idCard: "", // 身份证号
        mobile: "", // 联系方式
      },
      // 新增&编辑 - 弹框数据 - 校验
      addEditStudentDataRules: {
        fullName: [
          { required: true, message: "请输入学生姓名", trigger: "blur" },
        ],
        idCard: [
          { required: true, validator: this.$validateIdCard, trigger: 'blur' }
        ],
      },
      // 快速导入
      studentImportData: {
        dialogVisible: false, // 弹框状态
        doExportInDisabled: false, // 开始导入按钮状态
        totalNum: "0", // 导入总数
        errorNum: "0", // 失败总数
        correctNum: "0", // 成功总数
        batchId: "", // 用来上传的id
        importType: "", // 导入类型
        fileName: "", // 文件名称
        fileKey: "", // 文件key
        excelUrl: "", // 文件url
        progressVal: 0, // 导入进度条
      },
    };
  },
  watch: {},
  computed: {
  },
  mounted() { },
  created() {

  },
  methods: {
    // 查询学员 - 列表
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        ...
        {
          examinationId: this.examinationId,
          ... this.retrievalData
        }
      };
      this.doFetch(
        {
          url: "/biz/plan/pa-plan-examination/userList",
          params,
          pageNum,
        }, true, 10
      );
    },
    // 新增编辑 - 弹框 - 打开
    openAddEditStuden(row) {
      if (row) {
        this.addEditStudentData = { ...this.addEditStudentData, ...row };
      }
      this.addEditStudentData.dialogVisible = true;
    },
    // 新增编辑 - 弹框 - 关闭
    closeAddEditStuden() {
      this.addEditStudentData = this.$options.data().addEditStudentData;
    },
    // 新增编辑 - 弹框 - 确定
    sureAddEditStuden() {
      this.$refs['addEditStudentData'].validate((valid) => {
        if (valid) {
          this.$post('/biz/plan/pa-plan-examination/userSaveUpdate', {
            examinationId: this.examinationId,
            ...this.addEditStudentData
          }, 3000, true, 10)
            .then((res) => {
              this.$message.success('操作成功');
              this.closeAddEditStuden();
              this.getData(this.pageNum)
            })
            .catch((err) => {
              return;
            });
        }
      })
    },
    // 删除
    delete_data(row) {
      this.$post("/biz/plan/pa-plan-examination/userDel", {
        examinationUserId: row.examinationUserId
      }, 3000, true, 10)
        .then((res) => {
          this.$message.success('操作成功')
          this.getData(this.pageNum);
        })
        .catch((err) => {
          return;
        });
    },
    // 导入 - 打开弹框
    openStudentImportData() {
      this.studentImportData.dialogVisible = true;
    },
    // 导入 - 关闭弹框
    closeStudentImportData() {
      this.studentImportData = this.$options.data().studentImportData;
      this.getData();
    },
    // 导入 - 下载模板
    downloadExcelgs(templatePath) {
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = '/static/practical_user.xls';
      let templateNames = '学员导入模板.xls';
      link.setAttribute("download", templateNames);
      document.body.appendChild(link);
      link.click();
    },
    // 导入 - 导入模版
    uploadChange(file) {
      this.studentImportData.fileName = file.name;
      let size = file.size / 1024 / 1024;
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isXLSX = extension === "XLSX";
      const isxlsx = extension === "xlsx";
      const isXLS = extension === "XLS";
      const isxls = extension === "xls";
      if (!isXLSX && !isxlsx && !isXLS && !isxls) {
        this.$message.error("只能上传后缀是.xlsx或者.xls的文件");
        return;
      }
      if (size > 3) {
        this.$message.error("文件大小不能超过3M");
        return;
      }
      let formData = new FormData();
      formData.append("folder ", "PRACTICAL_QUESTION_BANK");
      formData.append("file ", file.raw);
      formData.append("fileType ", extension);
      this.$Postformat("/sys/file/upload/url", formData, 3000, true, 10)
        .then((result) => {
          this.studentImportData.fileKey = result.data.fileKey;
        })
        .catch((err) => {
          return;
        });
    },
    // 导入 - 开始导入
    doExport() {
      if (!this.studentImportData.fileKey) {
        this.$message({
          message: "请选择文件",
          type: "warning",
        });
      } else {
        this.studentImportData.doExportInDisabled = true;
        if (this.studentImportData.fileKey) {
          const params = {
            examinationId: this.examinationId,
            excelUrl: this.studentImportData.fileKey,
          };
          this.$post("/biz/plan/pa-plan-examination/importUser", params, 5000, true, 10)
            .then((res) => {
              this.doProgress(res.message);
            })
            .catch(() => {
              return;
            });
        } else {
          this.$message({
            message: "请选择文件",
            type: "warning",
          });
        }
      }
    },
    // 导入 - 导出错误数据
    doExportError() {
      if (!this.studentImportData.batchId || !this.studentImportData.fileKey) {
        this.$message({
          message: "还没有导入数据，请先导入文档",
          type: "warning",
        });
      } else {
        this.$post(
          "/biz/plan/pa-plan-examination/exportErrorUser",
          { batchId: this.studentImportData.batchId },
          5000, true, 10
        ).then((res) => {
          if (res.status == 0) {
            window.open(res.data);
          }
        });
      }
    },
    // 导入 - 获取导入进度
    doProgress(batchId) {
      this.$post(
        "/biz/plan/pa-plan-examination/importProgress",
        { batchId },
        5000, true, 10
      ).then((ret) => {
        if (!ret.data.status) {
          this.studentImportData.progressVal = ret.data.progress;
          setTimeout(() => {
            this.doProgress(batchId);
          }, 2000);
        } else {
          if (ret.data.message) {
            this.$message({
              type: "error",
              message: ret.data.message,
            });
          } else {
            this.studentImportData = { ...this.studentImportData, ...ret.data, progressVal: ret.data.progress }
            this.progressVal = ret.data.progress;
            this.$message({
              type: "success",
              message: "导入成功",
            });
          }
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.exportdialog {
  .upload-workers {
    width: 80px;
    height: 35px;

    .el-upload {
      width: 100% !important;
      height: 35px !important;
      border: none !important;

      .el-button {
        width: 100% !important;
        height: 32px !important;
        padding: 5px 0;
      }
    }
  }

  .el-dialog__title {
    font-size: 16px;
  }

  .export-box {
    width: 100%;
    margin-bottom: 20px;

    h1 {
      padding: 0 5px 10px;
      display: flex;
      align-items: flex-end;
      font-size: 40px;
      border-bottom: 1px dashed #ccc;

      span {
        margin-left: 20px;
        font-size: 14px;
      }
    }

    >div {
      padding-left: 30px;
    }

    .el-button.is-disabled,
    .el-button.is-disabled:focus,
    .el-button.is-disabled:hover {
      color: #fff;
      background: #c3c3c3;
      border: none;
    }
  }
}
</style>
