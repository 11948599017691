<template>
  <div>
    <div class="operationControl personalSpecialOrder">
      <div class="searchbox">
        <div title="姓名" class="searchboxItem ci-full">
          <span class="itemLabel">姓名:</span>
          <el-input v-model="retrievalData.fullname" type="text" size="small" placeholder="姓名" clearable />
        </div>
        <div title="身份证号" class="searchboxItem ci-full">
          <span class="itemLabel">身份证号:</span>
          <el-input v-model="retrievalData.idCard" type="text" size="small" placeholder="身份证号" clearable />
        </div>
        <el-button class="bgc-bv" round @click="getData()">查询</el-button>
        <el-button class="bgc-bv" round @click="openExaminer()" v-if="operateType != 'examine'">新增</el-button>
      </div>
    </div>
    <div class="framePage-scroll">
      <div class="ovy-a">
        <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
          style="width: 100%" stripe :header-cell-style="tableHeader">
          <el-table-column label="序号" align="center" type="index" :index="indexMethod" width="80px" />
          <el-table-column label="姓名" align="left" show-overflow-tooltip prop="fullname" min-width="120" />
          <el-table-column label="身份证号" align="left" show-overflow-tooltip prop="idCard" min-width="200" />
          <el-table-column label="手机号" align="left" show-overflow-tooltip prop="mobile" min-width="150" />
          <el-table-column label="创建时间" align="left" show-overflow-tooltip prop="createTime" width="160px" />
          <el-table-column label="操作" align="center" fixed="right" width="100px" v-if="operateType != 'examine'">
            <template slot-scope="scope">
              <!-- <el-button type="text" style="padding: 0px 5px" size="mini"
                @click="openExaminer(scope.row)">编辑</el-button> -->
              <el-button type="text" style="padding: 0px 5px" size="mini" @click="delete_data(scope.row)">删除</el-button>
            </template>
          </el-table-column>
          <Empty slot="empty" />
        </el-table>
      </div>
    </div>
    <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
    <!-- 弹框 - 新增&编辑 - 考生 -->
    <el-dialog title="新增考生" :visible.sync="addEditExaminerData.dialogVisible"
      width="500px" @close="closeAddEditStuden">
      <el-form ref="addEditExaminerData" label-width="100px" size="small" :rules="addEditExaminerDataRules"
        :model="addEditExaminerData">
        <el-form-item label="">
          请属于考评员身份证号，选择本场考试的考评员
        </el-form-item>
        <el-form-item label="考评员信息:" prop="adminId">
          <el-select size="small" v-model="addEditExaminerData.adminId" remote :remote-method="getIdCardList" filterable
            clearable placeholder="请筛选">
            <el-option v-for="item in idCardList" :key="item.adminId" :label="item.fullname + '-' + item.idCard"
              :value="item.adminId"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="closeAddEditStuden" class="bgc-bv">取 消</el-button>
        <el-button size="small" @click="sureAddEditStuden" class="bgc-bv">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
  name: "examPlan_info_3",
  components: {
    Empty,
    PageNum,
  },
  props: {
    // 考试计划id
    examinationId: {
      type: String,
      default: "",
    },
    // 审核人员操作
    operateType: {
      type: String,
      default: "",
    },
  },
  mixins: [List],
  data() {
    return {
      // 检索数据
      retrievalData: {
        fullname: "", // 姓名
        idCard: "", // 身份证号
      },
      // 添加&新增 - 弹框数据
      addEditExaminerData: {
        dialogVisible: false, // 弹框状态
        adminId: "", // 考生id
      },
      // 添加&新增 - 弹框数据校验
      addEditExaminerDataRules: {
        adminId: [
          { required: true, message: "请选择考评员信息", trigger: "change" },
        ],
      },
      idCardList: [], // 身份证号 - 下拉数据
    };
  },
  watch: {},
  computed: {
  },
  mounted() { },
  created() {
    this.getIdCardList();
  },
  methods: {
    // 查询 - 列表
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        ...
        {
          examinationId: this.examinationId,
          ... this.retrievalData
        }
      };
      this.doFetch(
        {
          url: "/biz/plan/pa-plan-examination/affiliationList",
          params,
          pageNum,
        }, true, 10
      );
    },
    // 新增编辑 - 弹框 - 打开
    openExaminer(row) {
      if (row) {
        this.addEditExaminerData = { ...this.addEditExaminerData, ...row };
      }
      this.addEditExaminerData.dialogVisible = true;
    },
    // 新增编辑 - 弹框 - 打开 - 身份证号检索
    getIdCardList(query) {
      this.$post("/biz/plan/pa-plan-examination/affiliationSelect", {
        examinationId: this.examinationId,
        fullname: query
      }, 3000, true, 10)
        .then((res) => {
          this.idCardList = res.data || [];
        })
        .catch(() => {
          return;
        });
    },
    // 新增编辑 - 弹框 - 关闭
    closeAddEditStuden() {
      this.addEditExaminerData = this.$options.data().addEditExaminerData;
    },
    // 新增编辑 - 弹框 - 确定
    sureAddEditStuden() {
      this.$refs['addEditExaminerData'].validate((valid) => {
        if (valid) {
          this.$post("/biz/plan/pa-plan-examination/addAffiliation", {
            examinationId: this.examinationId,
            adminId: this.addEditExaminerData.adminId,
          }, 3000, true, 10)
            .then((res) => {
              this.$message.success('操作成功');
              this.closeAddEditStuden();
              this.getData();
            })
            .catch((err) => {
              return;
            });
        }
      })
    },
    // 删除
    delete_data(row) {
      this.$post("/biz/plan/pa-plan-examination/delAffiliation", {
        examinationId: this.examinationId,
        adminId: row.adminId
      }, 3000, true, 10)
        .then((res) => {
          this.$message.success('操作成功')
          this.getData(this.pageNum);
        })
        .catch((err) => {
          return;
        });
    },
  },
};
</script>
<style lang="less" scoped></style>
